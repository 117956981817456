*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  position: relative;
  background-color: #EAF0F1;
  text-align: center;
}

html, body, #root, .todoBlock, .todoContainer {
  height: 100%;
}

.todoBlock {
  display: table;
  margin: 0 auto;
}

.todoContainer {
  max-width: 400px;
  background: #FBD28B;
  box-shadow: inset 0 0px 1px 1px #F3B431;
  padding-top: 16px;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
}

.title {
  display: inline-block;
  padding: 4px 16px 8px;
  border-top: 2px solid #2475B0;
  border-bottom: 2px solid #2475B0;
  margin-top: 8px;
}

.title svg {
  display: inline-block;
  vertical-align: middle;
}

.title span{
  font-size: 16px;
  margin-left: 16px;
  vertical-align: middle;
  color: #192A56;
}

.list-group-item {
  position: relative;
}

.items .list-group-item {
  padding-right: 52px;
}

.items .list-group-item:nth-child(odd) {
  background: rgba(255,255,255,0.5);
}

.items .list-group-item:nth-child(even) {
  background: rgba(255,255,255,0.75);
}

.items .checkIcon {
  position: absolute;
  right: 8px;
  top: 4px;
}

.checkIcon svg {
  font-size: 40px;
}